import { useState } from 'react';
import YaayLogo from './components/atom/Logo/Logo';
import {Helmet} from "react-helmet";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { selectContext, operatingContext, ShoppingBasketProvider } from './context/Context';
import Create from './pages/Create';
import Setup from './pages/Setup';
import Upload from './pages/Upload';
import Merch from './pages/Merch';
import Home from './pages/Home';
import Shop from './pages/Shop';
import CheckoutPage from './pages/Checkout';
import ConfirmationPage from './pages/Confirmation';
import ErrorPage from './pages/ErrorPage';
import ProductFull from './pages/ProductFull';
import DashboardPage from './pages/Dashboard';
import {
  ChakraProvider,
  theme
} from '@chakra-ui/react';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import AppWrapper from './components/organism/AppWrapper/APPWrapper';
import FirebaseProvider from "./firebase/firebaseContext";
import './App.css';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <ErrorPage />,
    handle: "Home",
    errorElement: <ErrorPage />
  },
  {
    path: "/upload",
    element: <Upload/>,
    handle: "Upload",
    errorElement: <ErrorPage />
  },  
  {
    path: "/merch",
    element: <Merch/>,
    handle: "Merchandise",
    errorElement: <ErrorPage />
  },
  {
    path: "/setup",
    element: <Setup/>,
    handle: "Setup",
    errorElement: <ErrorPage />
  },
  {
    path: "/create",
    element: <Create/>,
    handle: "Create"
  },
  {
    path: "/checkout",
    element: <CheckoutPage/>,
    handle: "Checkout",
    errorElement: <ErrorPage />
  },
  {
    path: "/shop",
    element: <Shop />,
    handle: "Shop",
    errorElement: <ErrorPage />
  },
  {
    path: "/orderConfirm",
    element: <ConfirmationPage />,
    handle: "Order Confirmation",
    errorElement: <ErrorPage />
  },
  {
    path: "/viewProduct",
    element: <ProductFull/>,
    handle: "Product View",
    errorElement: <ErrorPage />
  },
  {
    path: "/dashboard",
    element: <DashboardPage />,
    handle: "Dashboard",
    errorElement: <ErrorPage />
  }
]);

function App() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [imageAlbum, setImageAlbum] = useState([])
  const [selectedImage, setSelectedImage] = useState();
  const [selectedColour, setSelectedColour] = useState('#c42b20');
  const [selectedPlace, setSelectedPlace] = useState('frontFull');
  const [selectedOri, setSelectedOri] = useState('front');
  const [selectedSize, setSelectedSize] = useState('L');
  const [selectedMerch, setSelectedMerch] = useState(null);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [uploadedImageURI, setUploadedImageURI] = useState(null);
  const [isOpen, setIsOpen ] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState({state: false, imgId: null})
  const [openUpload, setOpenUpload] = useState(false)
  const [openURLUpload, setOpenURLUpload] = useState(false);
  const [openDDUpload, setOpenDDUpload] = useState(false);
  const [openAddConfirm, setOpenAddConfirm] = useState(false);
  const [currency, setCurrency] = useState({code: 'USD', symbol: '$'})

  const [currentSelection, setCurrentSelection] = useState(null)


  const initialOptions = {
    "client-id": "AR5JkOOY2yTcFv3ORwDW4Q_HaT-p0viB_VEkOpsxbdApIluR0c47ZKyUapXZLouvbv44d_5p6QXgdE9e",
    currency: "HKD",
    intent: "capture",
  };

  function stopWheelZoom(event) {
    if (event.ctrlKey === true) {
      event.preventDefault();
    }
  }
  function stopKeyZoom(event) {
    if (event.ctrlKey && [48,61,96,107,109,187,189].indexOf(event.keyCode) > -1) {
      event.preventDefault();
    }
  }
  document.addEventListener("keydown", stopKeyZoom);
  document.addEventListener('mousewheel', stopWheelZoom);
  document.addEventListener('DOMMouseScroll', stopWheelZoom);

  
  return (
    <FirebaseProvider>
      <operatingContext.Provider value={{
        isDrawerOpen, setIsDrawerOpen, 
        imageAlbum, setImageAlbum, 
        openUpload, setOpenUpload, 
        openURLUpload, setOpenURLUpload, 
        openDDUpload, setOpenDDUpload,
        openAddConfirm, setOpenAddConfirm,
        currency, setCurrency}}>
        <selectContext.Provider value={{
          selectedColour, setSelectedColour, 
          selectedPlace, setSelectedPlace, 
          uploadedImage, setUploadedImage, 
          isOpen,  setIsOpen, 
          selectedImage, setSelectedImage, 
          uploadedImageURI, setUploadedImageURI, 
          selectedSize, setSelectedSize, 
          selectedMerch, setSelectedMerch, 
          confirmOpen, setConfirmOpen,
          selectedOri, setSelectedOri,
          currentSelection, setCurrentSelection}}>
          <ShoppingBasketProvider>
            <PayPalScriptProvider options={initialOptions}>
              <ChakraProvider theme={theme}>
                <div className="App">
                  <Helmet>
                    <meta charSet="utf-8" />
                    <title>YAAY - Where Creators become entrepreneurs</title>
                    <meta name="description" content="YAAY - Leveling-up Creator Entrepreneurship in Web3" />
                    <meta property="og:title" content="YAAY - Where Creators become entrepreneurs"/>
                    <meta property="og:type" content="website"/>
                    <meta property="og:url" content="https://www.yaay.me"/>
                    <meta property="og:image" content={YaayLogo}/>
                  </Helmet>
                  <AppWrapper>
                    <RouterProvider router={router} />
                  </AppWrapper>
                </div>
              </ChakraProvider>
            </PayPalScriptProvider>
          </ShoppingBasketProvider>
        </selectContext.Provider>
      </operatingContext.Provider>
    </FirebaseProvider>
  );
}

export default App;
