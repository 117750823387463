import { useContext, useState, useEffect } from 'react';
import { operatingContext, selectContext } from '../../../context/Context';
import axios from "axios";
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box,
    Button
  } from '@chakra-ui/react'
import ImageAlbum from '../../molecule/ImageAlbum/ImageAlbum';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { getApp, initializeApp } from "firebase/app";

import ShoppingBasket from '../../molecule/ShoppingBasket/ShoppingBasket';
import { useShoppingBasket } from '../../../context/Context';
import { useNavigate } from 'react-router-dom';
import './Drawer.css'

const Drawer = () => {

    const [preview, setPreview] = useState()
    const {isDrawerOpen, setIsDrawerOpen} = useContext(operatingContext);
    const {uploadedImage, setUploadedImage} = useContext(selectContext)
    const {uploadedImageURI, setUploadedImageURI} = useContext(selectContext);
    const { selectedImage, setSelectedImage } = useContext(selectContext)
    const [isUploading, setIsUploading] = useState(false);
    const [uploadComplete, setUploadComplete] = useState(false);
    const { imageAlbum, setImageAlbum } = useContext(operatingContext);
    const { state } = useShoppingBasket();
    const firebaseConfig = {
        apiKey: "AIzaSyAo70-9MM2aZCx5bVGrytpdLkdARg6TofU",
        authDomain: "yaayinitial.firebaseapp.com",
        projectId: "yaayinitial",
        storageBucket: "yaayinitial.appspot.com",
        messagingSenderId: "468508283422",
        appId: "1:468508283422:web:4e0b1b195852c31185d40f",
        measurementId: "G-2JTGLCZNNE"
      };

    const navigate = useNavigate();
      

    // const handleUpload = () => {
    //     setIsUploading(true);
    //     const baseURL = "https://api.core3d.io/v1/uploads";
    //     const fd = new FormData();
    //     fd.append('file', uploadedImage)
    //     axios
    //       .post(baseURL, fd, { headers: { Authorization: `Bearer c3dak_NQCudjy8_8uZnX4oa6H9vEMWnirg29A2s` } })
    //       .then((response) => {
    //         setIsUploading(false);
    //         const imageURI = response.data.uri;
    //         setUploadedImageURI(imageURI)
    //         setUploadComplete(true)
    //       }).catch(error => {
    //         console.log('ERROR', error)
    //         setUploadComplete(false);
    //       });
    //   }

    useEffect(() => {
        if (!uploadedImage) {
            setPreview(undefined)
            return
        }
    
        const objectUrl = URL.createObjectURL(uploadedImage)
        setPreview(objectUrl)
    
        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [uploadedImage])

    const handleFileSelect = (e) =>{
        setUploadedImage(e.target.files[0])
    }

    const handleClose = () => {
        setIsDrawerOpen(false);
    }

    // ***** Upload to Firebase storage *****
  const handleUploadToStorage = () => {
    setIsUploading(true);
    const baseURL = "https://uploadfile-pcgrjii2ia-ew.a.run.app";
    const fd = new FormData();
    fd.append('image', uploadedImage)
    axios
      .post(baseURL, fd)
      .then((response) => {
        initializeApp(firebaseConfig)
        const firebaseApp = getApp();
        const storage = getStorage(firebaseApp, firebaseConfig.storageBucket);
        getDownloadURL(ref(storage, uploadedImage.name))
        .then((url) => {
            const imageArray = imageAlbum;
            const imageId = imageArray.length + 1;
            imageArray.unshift({id: imageId, src: url, uri: 'uri'})
            setImageAlbum(imageArray);
            handleUpload(imageId);
        })
      }).catch(error => {
        console.log('ERROR', error)
      });
  }

  const handleUpload = (imageId) => {
    setIsUploading(true);
    const baseURL = "https://api.core3d.io/v1/uploads";
    const fd = new FormData();
    fd.append('file', uploadedImage)
    axios
      .post(baseURL, fd, { headers: { Authorization: `Bearer c3dak_NQCudjy8_8uZnX4oa6H9vEMWnirg29A2s` } })
      .then((response) => {
        setIsUploading(false);
        const imageURI = response.data.uri;
        const index = imageAlbum.findIndex(item => item.id === imageId);
        const current = imageAlbum;
        current[index].uri = imageURI;
        setImageAlbum(current)
        setUploadedImageURI(imageURI)
        setUploadComplete(true)
        setSelectedImage(imageId)
        setPreview(null)
      }).catch(error => {
        console.log('ERROR', error)
        setUploadComplete(false);
      });
  }

  const handleCheckout = () => {
    setIsDrawerOpen(false);
    navigate('/checkout');
  }

  const renderTotalPrice = () => {
    if(state.items.length === 0) return 0.00
    let totalPrice = 0
    state.items.map((item) => {
      totalPrice = totalPrice + (item.itemCost * item.itemCount);
    })
    return totalPrice.toFixed(2);
  }

    return (
        <div className={`drawer ${(isDrawerOpen)? 'drawerOpen':''}`}>
            <div className='drawerContent'>
              <div className='drawerTitle'>Shopping Basket</div>
              <ShoppingBasket items={state.items} />
                  {/* <div className='drawerSelect'>
                    <input type='file' onChange={handleFileSelect}/>
                    <div className='drawerImageDisplay'>
                      {(uploadedImage && preview) &&  <img src={preview} style={{'aspectRatio': 'auto', 'width' : '120px'}} alt='Uploaded' /> }
                    </div>
                    <div className='uploadMessage'>
                    {(isUploading)?<div className='uploading'>Uploading...</div>: ''}
                    {(uploadComplete)?<div className='uploaded'>Image Uploaded.</div>:''}
                  </div>
                  <Button style={{'background': '#ff0098'}} colorScheme='blue' mr={3} onClick={handleUploadToStorage} isDisabled={(isUploading)?true:false}>Upload to Album</Button>
                  </div>  
                  <Accordion defaultIndex={0}>
                      <AccordionItem>
                          <h2>
                          <AccordionButton>
                              <Box as='span' flex='1' textAlign='left'>
                                  Default
                              </Box>
                              <AccordionIcon />
                          </AccordionButton>
                          </h2>
                          <AccordionPanel pb={4} style={{'overflow': 'hidden', 'display': 'block', 'opacity': 1, 'height': 'auto'}}>
                              <ImageAlbum />
                          </AccordionPanel>
                      </AccordionItem>
                  </Accordion> */}
                </div>
                <div className='drawerPrice'>
                  <div className='priceOuter'>
                    <div className='priceSpace'></div>
                    <div className='priceTitle'>Total:</div>
                    <div className='priceTotal'>£{renderTotalPrice()}</div>
                  </div>
                </div>
                <div className='drawerButtons'>
                  <Button style={{'background': '#ff0098'}} colorScheme='blue' mr={3} onClick={handleCheckout} >Checkout</Button>
                  {/* <Link className='continueButton' isdisabled={false} style={{'background': '#ff0098'}} to={`/checkout`}>Checkout</Link> */}

                   <Button style={{'background': '#ff0098', height: '35px'}} colorScheme='blue' mr={3} onClick={handleClose} >Continue shopping</Button>
                </div>
        </div>
    )
}

export default Drawer;