import { useState, useContext } from 'react';
import SizeBoxMini from '../../atom/SizeBoxMini/SizeBoxMini';
import { selectContext } from '../../../context/Context';
import './SizePanelMini.css'

const SizePanelMini = ( props ) => {
    const { disabled = false } = props;
    const [sizeType, setSizeType] = useState('in');
    const {selectedSize, setSelectedSize} = useContext(selectContext);
    const sizes = ['S', 'M', 'L', 'XL', 'XXL', 'XXXL'];
    const inches = ['35-37', '38-40', '41-43', '44-46', '47-49', '50-52'];
    const mm = ['87-94', '97-102', '104-109', '112-117', '119-125', '126-132']

    const handleSizeSelect = (size) => {
        setSelectedSize(size)
    }

    const renderSizes = () => {
        const sizeReturn = sizes.map((size, index) => {
            return <SizeBoxMini key={index} isButton={true} isdisabled={false} isSelected={(selectedSize === size)?true:false} returnClick={handleSizeSelect}>{size}</SizeBoxMini>
        })
        return sizeReturn;
    }

    return (
        <div className='sizePanel'>
            <div className='sizePanelLeft'>
                <div className='sizeOuter'>Size:
                <div className='panel'>
                    {disabled &&
                        <div className='blackout'></div>
                    }
                    <div className='sizeChartWrapper'>
                        <div className='sizeChartColMini'>{renderSizes()}</div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}

export default SizePanelMini;