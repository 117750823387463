import { useState, useContext } from 'react';
import SizeBox from '../../atom/SizeBox/SizeBox';
import SliderToggle from '../../atom/Toggle/Toggle';
import ColourPanel from '../ColourPanel/ColourPanel';
import { selectContext } from '../../../context/Context';
import PriceBreakdown from '../PriceBreakdown/PriceBreakdown';
import './SizePanel.css'

const SizePanel = ( props ) => {
    const { showBreakdown = true, disabled = false } = props;
    const [sizeType, setSizeType] = useState('in');
    const {selectedSize, setSelectedSize} = useContext(selectContext);
    const sizes = ['S', 'M', 'L', 'XL', 'XXL', 'XXXL'];
    const inches = ['35-37', '38-40', '41-43', '44-46', '47-49', '50-52'];
    const mm = ['87-94', '97-102', '104-109', '112-117', '119-125', '126-132']

    const handleSizeSelect = (size) => {
        setSelectedSize(size)
    }

    const renderSizes = () => {
        const sizeReturn = sizes.map((size, index) => {
            return <SizeBox key={index} isButton={true} isdisabled={false} isSelected={(selectedSize === size)?true:false} returnClick={handleSizeSelect}>{size}</SizeBox>
        })
        return sizeReturn;
    }

    const renderInches = () => {
        const inchReturn = inches.map((inch, index) => {
            return <SizeBox key={index} isButton={false} isdisabled={(sizeType==='in')?false:true}>{inch}</SizeBox>
        })
        return inchReturn;
    }

    const renderMM = () => {
        const mmReturn = mm.map((mm, index) => {
            return <SizeBox key={index} isButton={false} isdisabled={(sizeType==='mm')?false:true}>{mm}</SizeBox>
        })
        return mmReturn;
    }

    const HandleSliderChange = (sliderState) => {
        setSizeType((sliderState)? 'mm':'in');
    }

    return (
        <div className='sizePanel'>
            <div className='sizePanelLeft'>
                <div className='sizeOuter'>Size:
                <div className='panel'><div style={{width: '160px'}}></div><SliderToggle onWord={'MM'} OffWord={'Inch'} handleChange={HandleSliderChange}/></div>
                <div className='panel'>
                    {disabled &&
                        <div className='blackout'></div>
                    }
                    <div className='sizeChartWrapper'>
                        <div className='sizeChartCol'>{renderSizes()}</div>
                        <div className='sizeChartCol'>{renderInches()}</div>
                        <div className='sizeChartCol'>{renderMM()}</div>
                    </div>
                </div>
                </div>
            </div>
            {showBreakdown &&
                <div className='sizePanelRight'>
                    <PriceBreakdown />
                </div>
            }
        </div>
    )
}

export default SizePanel;