import './Create.css';
import YaayLogoSmall from '../components/atom/LogoSmall/LogoSmall';
import axios from "axios";
import { Core3D, Material, Model } from '@core3d/sdk';
import { Adapter } from '@core3d/sdk-adapter-three'
import { useContext, useEffect, useRef, useState } from 'react';
import FooterNew from '../components/molecule/FooterNew/FooterNew';
import SizePanel from '../components/molecule/SizePanel/SizePanel';
import ImageThumbPanel from '../components/molecule/ImageThumbPanel/ImageThumbPanel';
import BuyButton from '../components/atom/BuyButton/BuyButton';
import { selectContext, operatingContext } from '../context/Context';
import ShoppingBasket from '../components/molecule/ShoppingBasket/ShoppingBasket';
import PlacementPanel from '../components/molecule/PlacementPanel/PlacementPanel';
import Loading from '../components/atom/Loading/Loading'
import Drawer from '../components/atom/Drawer/Drawer';
import Header from '../components/molecule/Header/Header';
import { useShoppingBasket } from '../context/Context';
import { useNavigate } from 'react-router-dom';
import AddImage from '../components/molecule/AddImage/AddImage';
import ConfirmBox from '../components/atom/ConfirmBox/ConfirmBox';
import { getApp, initializeApp } from "firebase/app";

import {
  ChakraProvider,
  theme,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  renderPanel
} from '@chakra-ui/react';
import ColourPanel from '../components/molecule/ColourPanel/ColourPanel';
import OrientationPanel from '../components/molecule/OrientationPanel/OrientationPanel';
import { getStorage, ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { isValidFileExtension, getImageDPI, getPngDPI, getImageDetails, getImageDetailsFromURL, imageUrlToFile } from '../helpers/ImageHelpers';

const Create = () => {
  const {selectedColour, selectedPlace, selectedSize} = useContext(selectContext);
  const { state } = useShoppingBasket();
  const [showSizes, setShowSizes] = useState(false);
  const [isRendering, setIsRendering] = useState(false);

  const {confirmOpen, setConfirmOpen} = useContext(selectContext);
  const {uploadedImage, setUploadedImage} = useContext(selectContext)
  const [preview, setPreview] = useState()
  const [isUploading, setIsUploading] = useState(false);
  const [imageInfo, setImageInfo] = useState(null)
  const [imageSize, setImageSize] = useState({width: null, height: null });
  const {imageAlbum, setImageAlbum } = useContext(operatingContext);
  const {openUpload, setOpenUpload} = useContext(operatingContext);
  const {openURLUpload, setOpenURLUpload} = useContext(operatingContext);
  const {openDDUpload, setOpenDDUpload} = useContext(operatingContext);
  const {setOpenAddConfirm} = useContext(operatingContext);
  const [imageAssess, setImageAssess] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const [uploadComplete, setUploadComplete] = useState(false);
  const {uploadedImageURI, setUploadedImageURI} = useContext(selectContext);
  const {selectedImage, setSelectedImage } = useContext(selectContext);
  const [imageUrl, setImageUrl] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadedImageUrl, setUploadedImageUrl] = useState('');
  const [error, setError] = useState('');
  const [ddFiles, setDDFiles] = useState(null);

  const { dispatch } = useShoppingBasket();
  let navigate = useNavigate();

  const firebaseConfig = {
    apiKey: 'AIzaSyAo70-9MM2aZCx5bVGrytpdLkdARg6TofU',
    authDomain: 'yaayinitial.firebaseapp.com',
    projectId: 'yaayinitial',
    storageBucket: 'yaayinitial.appspot.com',
    messagingSenderId: '468508283422',
    appId: '1:468508283422:web:4e0b1b195852c31185d40f',
    measurementId: 'G-2JTGLCZNNE'
  };

  const handleAdd = (id, itemName, sku, itemCost, itemSize, itemColour, itemCount, itemImage) => {
    dispatch({
      type: 'ADD_ITEM',
      payload: { id: id, name: itemName, sku: sku, itemCost: itemCost, itemColour, itemSize: itemSize, itemCount: itemCount, itemImage: itemImage }
    });
  };

  const handleEdit = (id, newCount) => {
    dispatch({ 
      type: 'EDIT_ITEM', 
      payload: { id: id, updates: { itemCount: newCount } } });
  };

  const handleConfirm = result => {
    if (result) {

      if(result) {
        const findImageIndex = (element) => element.id === confirmOpen.imgId;
        imageAlbum.splice(imageAlbum.findIndex(findImageIndex), 1)
        setImageAlbum(imageAlbum)
      }
    }
    
    setConfirmOpen({state: false, imgId: null});
  }

  // TODO: Move the following code to helper file

  const handleUploadClose = () => {
    setOpenUpload(false);
    setOpenURLUpload(false);
    setOpenDDUpload(false);
    setUploadedImage(null);
  }

  const handleUploadToStorage = () => {
    setIsUploading(true);
    setUploadComplete(false)
    const baseURL = "https://uploadfile-pcgrjii2ia-ew.a.run.app";
    const fd = new FormData();
    fd.append('image', uploadedImage)
    axios
      .post(baseURL, fd)
      .then((response) => {
        initializeApp(firebaseConfig)
        const firebaseApp = getApp();
        const storage = getStorage(firebaseApp, firebaseConfig.storageBucket);
        getDownloadURL(ref(storage, uploadedImage.name))
        .then((url) => {
            const imageArray = imageAlbum;
            const imageId = imageArray.length + 1;
            imageArray.unshift({id: imageId, src: url, uri: 'uri', imgWidth: imageSize.width, imgHeight: imageSize.height})
            setImageAlbum(imageArray);
            handleUpload(imageId);
        })
      }).catch(error => {
        console.log('ERROR', error)
      });
  }

  const handleUpload = (imageId) => {
    setIsUploading(true);
    const baseURL = "https://api.core3d.io/v1/uploads";
    const fd = new FormData();
    fd.append('file', uploadedImage)
    axios
      .post(baseURL, fd, { headers: { Authorization: `Bearer c3dak_NQCudjy8_8uZnX4oa6H9vEMWnirg29A2s` } })
      .then((response) => {
        setIsUploading(false);
        const imageURI = response.data.uri;
        const index = imageAlbum.findIndex(item => item.id === imageId);
        const current = imageAlbum;
        current[index].uri = imageURI;
        setImageAlbum(current)
        setUploadedImageURI(imageURI)
        setUploadComplete(true)
        setSelectedImage(imageId)
        setPreview(null)
        setImageInfo(null)
        setImageSize({width: null, height: null });
      }).catch(error => {
        console.log('ERROR', error)
        setUploadComplete(false);
      });
  }

  const handleDragDrop = async (files) => {
    const file = files[0];
    if (file) {
      setOpenDDUpload(true)
      const ext = file.name.split('.').pop();
      if (isValidFileExtension(ext)) {
        getImageDetails(file, (info) => {
          setImageSize({width: info.width, height: info.height, realWidth: info.realWidth, realHeight: info.realHeight});
          //setUploadError('');
        });
        if (ext === 'png'){
            getPngDPI(file, (err, dpi) => {
                if (err) {
                  setUploadError(err.message);
                  setUploadedImage(null);
                  setImageInfo(null);
                } else {
                  setImageInfo(dpi.x);
                  setUploadError('');
                  setUploadedImage(file);
                }
              });
        } else {
            getImageDPI(file, (dpi) => {  
                setImageInfo((dpi.x.numerator/dpi.x.denominator));
                setUploadError('');
              });
            setUploadedImage(file)
        }
        const objectUrl = URL.createObjectURL(file)
        if(imageInfo>140){
          setImageAssess(true)
        } else {
          setImageAssess(false)
        }
        setUploadError(null);
        setDDFiles([{file: file, imageURL: objectUrl, imageSize: 'imageDetails', imageInfo: imageInfo, imageAssess: imageAssess}]);

      } else {
        setUploadedImage(null);
        setUploadError('Invalid file type. Only images and PDFs are allowed.');
      }
    }
    
    // ********************************
    // Original multi file handling
    // const dragFiles = [...files];
    // setOpenDDUpload(true)
    // const ddFileArray = []

    // dragFiles.forEach(file => {
    //   let imageDetails, imageInfo, imageAssess
    //   if (file) {
        
    //     const ext = file.name.split('.').pop();
    //     if (isValidFileExtension(ext)) {
          
    //       imageDetails = getImageDetails(file, (info) => {
    //         //setImageSize({width: info.width, height: info.height, realWidth: info.realWidth, realHeight: info.realHeight});
    //         imageDetails = {width: info.width, height: info.height, realWidth: info.realWidth, realHeight: info.realHeight}
    //       })
    //       if (ext === 'png'){
    //           getPngDPI(file, (err, dpi) => {
    //               if (err) {
    //                 setUploadError(err.message);
    //                 // setUploadedImage(null);
    //                 // setImageInfo(null);
    //               } else {
    //                 setUploadError('');
    //                 //setImageInfo(dpi.x);
    //                 imageInfo = dpi.x
    //                 //setUploadedImage(file);
    //               }
    //             });
    //       } else {
    //           getImageDPI(file, (dpi) => {  
    //               //setImageInfo((dpi.x.numerator/dpi.x.denominator));
    //               imageInfo = (dpi.x.numerator/dpi.x.denominator)
    //               setUploadError('');
    //             });
    //           //setUploadedImage(file)
    //       }
    //       const objectUrl = URL.createObjectURL(file)
    //       if(imageInfo>140){
    //         imageAssess = true
    //       } else {
    //         imageAssess = false
    //       }
    //       setUploadError(null);
    //       ddFileArray.push({file: file, imageURL: objectUrl, imageSize: imageDetails, imageInfo: imageInfo, imageAssess: imageAssess})
    //     } else {
    //       setUploadedImage(null);
    //       setUploadError('Invalid file type. Only images and PDFs are allowed.');
    //     }
    //   }
    // })
  }

  const handleDDUploadToStorage = async(uploadFile) => {
    setIsUploading(true);
    setUploadComplete(false)
    const baseURL = "https://uploadfile-pcgrjii2ia-ew.a.run.app";
    const fd = new FormData();
    fd.append('image', uploadFile)
    axios
      .post(baseURL, fd)
      .then((response) => {
        initializeApp(firebaseConfig)
        const firebaseApp = getApp();
        const storage = getStorage(firebaseApp, firebaseConfig.storageBucket);
        getDownloadURL(ref(storage, uploadFile.name))
        .then((url) => {
            const imageArray = imageAlbum;
            const imageId = imageArray.length + 1;
            imageArray.unshift({id: imageId, src: url, uri: 'uri', imgWidth: imageSize.width, imgHeight: imageSize.height})
            setImageAlbum(imageArray);
            handleUpload(imageId);
        })
      }).catch(error => {
        console.log('ERROR', error)
      });
  }

  const handleURLUpload = async () => {
    const ext = imageUrl.split('.').pop();
    const imgFile = await imageUrlToFile(imageUrl);
    if (isValidFileExtension(ext)) {
      try {
        const response = await fetch(imageUrl);
        
        if (!response.ok) {
          throw new Error('Failed to fetch image.');
        } else {
          const img = new Image();
          img.src = imageUrl;
  
          img.onload = () => {
            setUploadedImage(imgFile);
            getImageDetailsFromURL(imageUrl, (err, info) => {
              if (err) {
                setError(err.message);
                // setImageDetails(null);
              } else {
                setError('');
                //setImageDetails(details);
                setImageSize({width: info.width, height: info.height, realWidth: info.realWidth, realHeight: info.realHeight});
                setImageInfo((info.dpi.x));
              }
            });
          }
        
          // img.onload = () => {
          //   
          //   // getImageDetails(file, (info) => {
          //   //   setImageSize({width: info.width, height: info.height, realWidth: info.realWidth, realHeight: info.realHeight});
          //   //   //setUploadError('');
          //   // });
          // }
        }
  
        initializeApp(firebaseConfig)
        const firebaseApp = getApp();
        const storage = getStorage(firebaseApp, firebaseConfig.storageBucket);
  
        const blob = await response.blob();
        const fileName = imageUrl.split('/').pop();
        //const storageRef = ref(storage, `images/${fileName}`);
        const storageRef = ref(storage, fileName);
        const uploadTask = uploadBytesResumable(storageRef, blob);
  
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('progress', progress)
            setUploadProgress(progress);
          },
          (error) => {
            console.log('ERROR', error.message)
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
              const imageArray = imageAlbum;
              const imageId = imageArray.length + 1;
              imageArray.unshift({id: imageId, src: url, uri: 'uri', imgWidth: imageSize.width, imgHeight: imageSize.height})
              setImageAlbum(imageArray);
              setImageInfo(null)
              setImageSize({width: null, height: null })
              //handleUpload(imageId);
            });
          }
        );
      } catch (err) {
        console.log('ERROR', err)
      }
    } else {
      setUploadedImage(null);
      setUploadError('Invalid file type. Only images and PDFs are allowed.');
    }
  };


  // TODO: Move code above 

  const core3d = new Core3D({
    apiKey: 'c3dak_NQCudjy8_8uZnX4oa6H9vEMWnirg29A2s',
    createAdapter: ctx => new Adapter(ctx),
  });

  const container = useRef(null)

  const setRGBColour = () => {
    let returnColour;
    switch(selectedColour) {
      case '#000000':
        returnColour = [0,0,0,1];
        break;
      case '#ff2d2d':
        returnColour = [1,0.176,0.176,1];
        break;
      case '#2d32ff':
        returnColour = [0.176,0.196,1,1];
        break;
      case '#73c881':
        returnColour = [0.451,0.784,0.506,1];
        break;
      case '#deeb06':
        returnColour = [0.871,0.922,0.024,1];
        break;
      default:
        returnColour = [1,1,1,1];
    }

    return returnColour;
  }

  const setPlacement = () => {
    if(selectedPlace === 'frontLeftPocket'){
      return 1;
    } else {
      return 0;
    }
  }

  const buildRender = async () => {
    setIsRendering(true);
    const design = core3d.createDesign();
    const model = await core3d.loadModel(Model.Tee);
    const cotton = await core3d.loadMaterial(Material.Cotton);
    const graphic = await core3d.loadGraphic(uploadedImageURI)

    // console.log('model', model)
    // console.log('cotton', cotton)

    design.setModel(model);

    // console.log('design', design)

    for (const mesh of design.listMeshes()) {
        // console.log(mesh)
        design.apply(cotton, mesh);
    }

    cotton.setOption('color', setRGBColour());
    const zone = design.listZones().find(zone => /front/i.test(zone.getName()));
    if ( zone ) {
      const assignment = design.apply(graphic, zone);
      const placement = zone.placements[setPlacement()];
      if (placement) {
        assignment.setPlacement(placement.name)
      }
    }

    await design.render();

    const scene = await core3d.loadScene({
      layout: 'front',
      size: [512, 512],
      target: design,
    });

    scene.fit();
    scene.start();

    // console.log('.....', scene)
    //document.body.append(scene.element);
    container.current.innerHTML = "";
    container.current.append(scene.element);
    setIsRendering(false);
  }

  const handleFileSelect = (e) =>{
    setUploadedImage(e.target.files[0])
  }

  const handleBasketAdd = () => {
    const itemName = 'Custom Round Neck T-Shirt';
    const sku = 'SKU7364287634'
    const size = selectedSize;
    const colour = selectedColour;
    const itemCost = 39.99
    const id = `${sku}-${uploadedImageURI}-${size}-${colour}`
    const result = state.items.filter(obj => { return obj.id === id})
    if(result.length === 0){
      handleAdd(id, itemName, sku, itemCost, size, colour, 1);
    } else {
      const currentCount = result[0].itemCount;
      handleEdit(id, currentCount + 1)
    }
    setOpenAddConfirm(true);
  }

  // ***** Upload to Firebase storage *****
  // const handleUpload = () => {
  //   setIsUploading(true);
  //   console.log('upload', uploadedImage)
  //   const baseURL = "https://uploadfile-pcgrjii2ia-ew.a.run.app";
  //   const fd = new FormData();
  //   fd.append('image', uploadedImage)
  //   axios
  //     .post(baseURL, fd)
  //     .then((response) => {
  //       setIsUploading(false);
  //       console.log(response)
  //     }).catch(error => {
  //       console.log('ERROR', error)
  //     });
  // }

  // const handleUpload = () => {
  //   setIsUploading(true);
  //   const baseURL = "https://api.core3d.io/v1/uploads";
  //   const fd = new FormData();
  //   fd.append('file', uploadedImage)
  //   axios
  //     .post(baseURL, fd, { headers: { Authorization: `Bearer c3dak_NQCudjy8_8uZnX4oa6H9vEMWnirg29A2s` } })
  //     .then((response) => {
  //       setIsUploading(false);
  //       const imageURI = response.data.uri;
  //       setUploadedImageURI(imageURI)
  //       setUploadComplete(true)
  //     }).catch(error => {
  //       console.log('ERROR', error)
  //       setUploadComplete(false);
  //     });
  // }


  

  useEffect(() => {
    if(uploadedImageURI){
      //buildRender()
    }
  }, [selectedColour, selectedPlace, uploadedImageURI])

  
  return (
    
    <div className='container' id="container">
      <Header />
      {/* <Modal isOpen={isOpen} onClose={onClose} isCentered size={'md'} scrollBehavior='inside' closeOnOverlayClick={false} closeOnEsc={false}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Lets start at the start....</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <div className='modalTitle'>Upload an image to start creating your merchandise</div>
              <div className='modalSelect'>
                <input type='file' onChange={handleFileSelect}/>
                <div className='imageDisplay'>
                  {uploadedImage &&  <img src={preview} style={{'aspect-ratio': 'auto'}} alt='Uploaded' /> }
                </div>
              </div>
            </ModalBody>

            <ModalFooter>
              <div className='uploadMessage'>
                {(isUploading)?<div className='uploading'>Uploading...</div>: ''}
                {(uploadComplete)?<div className='uploaded'>Image Uploaded.</div>:''}
              </div>
              <Button style={{'background': '#ff0098'}} colorScheme='blue' mr={3} onClick={handleUpload} isDisabled={(uploadComplete)?true:false}>Upload</Button>
              <Button style={{'background': '#ff0098'}} colorScheme='blue' mr={3} onClick={onClose} isDisabled={(uploadedImageURI)?false:true}>
                Continue
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal> */}
        <ConfirmBox text={'Delete Image?'} open={confirmOpen.state} handleConfirm={handleConfirm}/>
            <div className={`topDrawer ${(openUpload)?'topDrawerOpen':''}`}>
              <div className='detailTitle'>Image File Upload</div>
              <div className='uploadButtonHolder'>
                <label htmlFor="fileUpload" className="continueButton">Add Image</label>
                <input id='fileUpload' type='file' onChange={handleFileSelect}/>
              </div>
              {/* <div className='drawerImageDisplay'>
                  {(uploadedImage && preview) &&  <div style={{'aspectRatio': 'auto'}} ><img src={preview} style={{'height' : '150px'}} alt='Uploaded' /></div> }
              </div> */}
              <div className='drawerDisplayOuter'>
                  <div className='drawerImageDisplay'>
                    {!preview && <div className='NoImage'>Select an image to see a preview</div>}
                    {(uploadedImage && preview) &&  <div style={{'aspectRatio': 'auto'}} ><img src={preview} style={{'height' : '150px'}} alt='Uploaded' /></div> }
                  </div>
                  {/* {uploadProgress > 0 && <p>Upload Progress: {uploadProgress.toFixed(2)}%</p>}
                  {error && <p style={{ color: 'red' }}>{error}</p>} */}
                  <div className='uploadStats'>
                    <div className='detailTitle'>Image Details</div>
                    <div>Size: {(imageSize.width)?`${imageSize.width}px x ${imageSize.height}px`:''}</div>
                    <div>Resolution: {(imageInfo)? `${imageInfo}dpi`:''}</div>
                    <div className='imageAssess'>
                      {(imageAssess===true) && <div className='itsGood'>That image looks all good.</div>}
                    </div>
                  </div>
                </div>
                <div className='imageErrorPanel'>
                  {uploadError&&<div className='uploadError'>{uploadError}</div>}
                  {(isUploading)?<div className='uploading'>Uploading...</div>: ''}
                  {(uploadComplete)?<div className='uploaded'>Image Uploaded to Album.<br/><span className='uploadSubText'>To upload another image, click the button above.</span></div>:''}
                </div>
              {/* <div className='uploadStats'>
                <div className='detailTitle'>Image Details</div>
                <div>Size: {(imageSize.width)?`${imageSize.width}px x ${imageSize.height}px (${imageSize.realWidth?.toFixed(2)}cm x ${imageSize.realHeight?.toFixed(2)}cm)`:''}</div>
                <div>Resolution: {(imageInfo)? `${imageInfo}dpi`:''}</div>
              </div>
              <div className='uploadMessage'>
              {(isUploading)?<div className='uploading'>Uploading...</div>: ''}
              {(uploadComplete)?<div className='uploaded'>Image Uploaded.</div>:''}
              </div> */}
              <div className='buttonHolder'>
                <Button style={{'background': '#ff0098'}} colorScheme='blue' mr={3} onClick={handleUploadToStorage} isdisabled={(isUploading || !preview)?true:false}>Upload to Album</Button>
                <Button style={{'background': '#ff0098'}} colorScheme='blue' mr={3} onClick={handleUploadClose} isdisabled={(isUploading)?true:false}>Close</Button>
              </div>
            </div>
            {/* URL Upload */}
            <div className={`topDrawer ${(openURLUpload)?'topDrawerOpen':''}`}>
              {uploadError}
              <div className='urlUploadOuter'>
                <div className='detailTitle'>Image URL Uploader</div>
                <input
                  className='urlUpload'
                  type="text"
                  placeholder="Enter image URL"
                  value={imageUrl}
                  onChange={(e) => setImageUrl(e.target.value)}
                />
                <Button style={{'background': '#ff0098'}} colorScheme='blue' mr={3}  onClick={handleURLUpload}>Upload Image</Button>
                <div className='drawerDisplayOuter'>
                  <div className='drawerImageDisplay'>
                    {!preview && <div className='NoImage'>Select an image to see a preview</div>}
                    {(uploadedImage && preview) &&  <div style={{'aspectRatio': 'auto'}} ><img src={preview} style={{'height' : '150px'}} alt='Uploaded' /></div> }
                  </div>
                  {/* {uploadProgress > 0 && <p>Upload Progress: {uploadProgress.toFixed(2)}%</p>}
                  {error && <p style={{ color: 'red' }}>{error}</p>} */}
                  <div className='uploadStats'>
                    <div className='detailTitle'>Image Details</div>
                    <div>Size: {(imageSize.width)?`${imageSize.width}px x ${imageSize.height}px (${imageSize.realWidth?.toFixed(2)}cm x ${imageSize.realHeight?.toFixed(2)}cm)`:''}</div>
                    <div>Resolution: {(imageInfo)? `${imageInfo}dpi`:''}</div>
                  </div>
                </div>
                <div className='uploadMessage'>
                {(isUploading)?<div className='uploading'>Uploading...</div>: ''}
                {(uploadComplete)?<div className='uploaded'>Image Uploaded.</div>:''}
                </div>
                <div className='buttonHolder'>
                  <Button style={{'background': '#ff0098'}} colorScheme='blue' mr={3} onClick={handleUploadToStorage} disabled={(isUploading || !preview)?true:false}>Upload to Album</Button>
                  <Button style={{'background': '#ff0098'}} colorScheme='blue' mr={3} onClick={handleUploadClose} disabled={(isUploading)?true:false}>Close</Button>
                </div>
              </div>
            </div>
            {/* DD Upload */}
            <div className={`topDrawer ${(openDDUpload)?'topDrawerOpen':''}`}>
              {uploadError}
              <div className='urlUploadOuter'>
                <div className='detailTitle'>Image Uploader</div>
                 <div className='drawerDisplayOuter'>
                  {/* <div className='drawerImageDisplay'>
                    {!preview && <div className='NoImage'>Select an image to see a preview</div>}
                    {(uploadedImage && preview) &&  <div style={{'aspectRatio': 'auto'}} ><img src={preview} style={{'height' : '150px'}} alt='Uploaded' /></div> }
                  </div>
                  <div className='uploadStats'>
                    <div className='detailTitle'>Image Details</div>
                    <div>Size: {(imageSize.width)?`${imageSize.width}px x ${imageSize.height}px (${imageSize.realWidth?.toFixed(2)}cm x ${imageSize.realHeight?.toFixed(2)}cm)`:''}</div>
                    <div>Resolution: {(imageInfo)? `${imageInfo}dpi`:''}</div>
                  </div> */}
                  <div className='fileListOuter'>
                    {ddFiles?.map((file, index) => {
                      return (
                        <div className='fileListRow'>
                          <div style={{'aspectRatio': 'auto'}}><img src={file.imageURL} style={{'height' : '80px'}} alt='Uploaded' /></div>
                          <div className='ddDetailsPanel'>
                            <div className='detailTitle'>Image Details</div>
                            <div>Size: {(file?.imageDetails?.width)?`${file?.imageDetails?.width}px x ${file?.imageDetails?.height}px (${file?.imageDetails?.realWidth?.toFixed(2)}cm x ${file?.imageDetails?.realHeight?.toFixed(2)}cm)`:''}</div>
                            <div>Resolution: {(file.imageInfo)? `${file.imageInfo}dpi`:''}</div>
                          </div>
                          <div className='imageCheck'>
                            {file.imageAssess}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                  
                </div>
                <div className='uploadMessage'>
                {(isUploading)?<div className='uploading'>Uploading...</div>: ''}
                {(uploadComplete)?<div className='uploaded'>Image Uploaded.</div>:''}
                </div>
                <div className='buttonHolder'>
                  <Button style={{'background': '#ff0098'}} colorScheme='blue' mr={3} onClick={handleUploadToStorage} disabled={(isUploading || !preview)?true:false}>Upload to Album</Button>
                  <Button style={{'background': '#ff0098'}} colorScheme='blue' mr={3} onClick={handleUploadClose} disabled={(isUploading)?true:false}>Close</Button>
                </div>
              </div>
            </div>
      <main>
        <section>
          <div className='createContent'>
            <Drawer />
            <div className='leftPanel'>
              <div className='collectionTitle'></div>
              {(showSizes === false) && <div className='specPanel'>
                <div className='panel'><ImageThumbPanel /></div>
                <div className='panel' style={{height: '100%'}}>
                  <AddImage returnDragDrop={handleDragDrop} />
                </div>
                <div className='buttonPanelBot'>
                  <button className='continueButton' onClick={() => navigate(-1)}>back to garments</button>
                  <button className='continueButton' onClick={() => setShowSizes(true)}>continue</button>
                </div>
              </div>}
              {(showSizes === true) && <div className='specPanel'>
                <div className='panel'><ImageThumbPanel /></div>
                <div className='panel'><SizePanel/></div>
                <div className='buttonPanelBot'>
                  <button className='continueButton' onClick={() => setShowSizes(false)}>review design</button>
                  <button className='continueButton' onClick={() => handleBasketAdd()}>add to basket</button>
                </div>
              </div>}
              
            </div>
            <div className='rightPanel'>
              <div className='collectionTitle'></div>
              <div className='designPanel'>
                <div className='designTitle'></div>
                <div className='design'>
                  {/* <Loading /> */}
                  {isRendering && <div className='renderPanel'>Placeholder. Please press continue to advance....</div> }
                  <div ref={ container } />
                  <div className='bottomPanel'>
                    <OrientationPanel />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
           
  );
}

export default Create;
