import React, { useState, useEffect } from 'react';
import { useShoppingBasket } from '../../../context/Context';
import './BasketAddItem.scss'

const BasketAddItem = ({ item }) => {
  const { dispatch } = useShoppingBasket();
  const [isEditing, setIsEditing] = useState(false);
  const [editedItem, setEditedItem] = useState(item.name);
  const [currentCount, setCurrentCount]= useState(0)
  const [itemTotal, setItemTotal] = useState(0)
  const [ mainImageSrc, setMainImageSrc] = useState()

  const translateColour = ( colour ) => {
    let returnColour;
    switch(colour) {
      case '#000000':
        returnColour = 'Black';
        break;
      case '#ff2d2d':
        returnColour = 'Red';
        break;
      case '#2d32ff':
        returnColour = 'Blue';
        break;
      case '#73c881':
        returnColour = 'Green';
        break;
      case '#deeb06':
        returnColour = 'Glo Yellow';
        break;
      default:
        returnColour = 'White';
    }
    return returnColour;
  }

  useEffect(() => {
    const imgIndex = item.availColours.findIndex((element) => element === item.colour)
    setMainImageSrc(item.availImages[imgIndex]);
  })

  return (
    <div className="addBasketOuter">
    <div className="addBasketItem">
      <div className="addBasketImage">
        <img src={mainImageSrc} alt={item.itemName} className="product-frame"/>
      </div>
      <div className="product-details">
        <h1><strong>{item.itemName}</strong></h1>
        <p><strong>{translateColour(item.itemColour)}, Size {item.size}</strong></p>
      </div>
    </div>
  </div>
  );
};

export default BasketAddItem;