import { getApp, initializeApp } from 'firebase/app';
import { getDatabase, ref, set, get  } from "firebase/database";
import { getStorage, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import axios from "axios";
 
 const firebaseConfig = {
 apiKey: process.env.REACT_APP_API_KEY,
 authDomain: process.env.REACT_APP_AUTH_DOMAIN,
 projectId: process.env.REACT_APP_PROJECT_ID,
 storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
 messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
 appId: process.env.REACT_APP_APP_ID,
 measurementId: process.env.REACT_APP_MEASUREMENT_ID,
 databaseURL: "https://yaayinitial-default-rtdb.europe-west1.firebasedatabase.app/",
};
 


initializeApp(firebaseConfig)
const firebaseApp = getApp();
const db = getDatabase(firebaseApp);
const storage = getStorage(firebaseApp, firebaseConfig.storageBucket);

export const writeOrderData = async (userId, imageUrl) => {
    console.log('I am writing', db)
    set(ref(db, 'users/' + userId), {
      username: 'Test Name',
      email: 'test email',
      profile_picture : 'testURL'
    });
  }

  //****** Access Codes **********/

  export const writeNewAccessCode = async (aCode, createDate, maxUsage, codeAttributes) => {
    console.log('I am creating a new access code', db)
    set(ref(db, 'accessCodes/' + aCode), {
      createDate: createDate,
      maxUsage: maxUsage,
      usage : [],
      locked: false,
      orderComplete: false,
      codeAttributes: codeAttributes
    });
  }

  export const readAccessCodes = async (path) => {
    const dbRef = ref(db, path);
    return get(dbRef).then((snapshot) => {
      if (snapshot.exists()) {
        return snapshot.val();
      } else {
        console.log("No data available");
        return null;
      }
    }).catch((error) => {
      console.error("Error reading data:", error);
    });
  };

  // Write data to the database
    export const writeData = (path, data) => {
    return set(ref(db, path), data);
  };
  
  // Read data from the database
  export const readData = (path) => {
    const dbRef = ref(db, path);
    return get(dbRef).then((snapshot) => {
      if (snapshot.exists()) {
        return snapshot.val();
      } else {
        console.log("No data available");
        return null;
      }
    }).catch((error) => {
      console.error("Error reading data:", error);
    });
  };
  
  // Update existing data in the database
//   export const updateData = (path, newData) => {
//     return update(ref(db, path), newData);
//   };
export const handleFirebaseStorage = async (uploadedImage) => {
    console.log('HandleFirebaseStorage', uploadedImage)
    const baseURL = "https://uploadfile-pcgrjii2ia-ew.a.run.app";
    const fd = new FormData();
    fd.append('image', uploadedImage)
    axios
      .post(baseURL, fd)
      .then((response) => {
        const returnURL = getDownloadURL(ref(storage, uploadedImage.name))
        .then((url) => {
            const retObject = {id: '', src: url, uri: 'uri', imgWidth: '', imgHeight: ''}
            writeOrderData('12543', url)
            //return retObject
            return Promise.resolve(1);
        })
        return returnURL;
    }).catch(error => {
        console.log('ERROR', error)
      });
  }