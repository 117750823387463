import { useContext, useState, useEffect } from 'react';
import { operatingContext, selectContext } from '../context/Context';
import YaayLogoSmall from '../components/atom/LogoSmall/LogoSmall';
import axios from "axios";
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box,
    Button
  } from '@chakra-ui/react'
import ImageAlbum from '../components/molecule/ImageAlbum/ImageAlbum';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { getApp, initializeApp } from "firebase/app";
import { Link, useNavigate} from 'react-router-dom';
import { isValidFileExtension, getImageDPI, getPngDPI, getImageDetails } from '../helpers/ImageHelpers';
import MerchPanel from '../components/molecule/MerchPanel/MerchPanel';
import Header from '../components/molecule/Header/Header';


import './Merch.css';

const Merch = () => {
    const [preview, setPreview] = useState()
    const {uploadedImage, setUploadedImage} = useContext(selectContext)
    const {uploadedImageURI, setUploadedImageURI} = useContext(selectContext);
    const {selectedImage, setSelectedImage } = useContext(selectContext)
    const {selectedMerch} = useContext(selectContext)
    const [isUploading, setIsUploading] = useState(false);
    const [uploadComplete, setUploadComplete] = useState(false);
    const [uploadError, setUploadError] = useState(null);
    const {imageAlbum, setImageAlbum } = useContext(operatingContext);
    const [imageInfo, setImageInfo] = useState(null)
    const [imageSize, setImageSize] = useState({width: null, height: null });
    const [openUpload, setOpenUpload] = useState(false)
    const firebaseConfig = {
        apiKey: "AIzaSyAo70-9MM2aZCx5bVGrytpdLkdARg6TofU",
        authDomain: "yaayinitial.firebaseapp.com",
        projectId: "yaayinitial",
        storageBucket: "yaayinitial.appspot.com",
        messagingSenderId: "468508283422",
        appId: "1:468508283422:web:4e0b1b195852c31185d40f",
        measurementId: "G-2JTGLCZNNE"
      };
    const navigate = useNavigate();

      useEffect(() => {
        if (!uploadedImage) {
            setPreview(undefined)
            return
        }
    
        const objectUrl = URL.createObjectURL(uploadedImage)
        setPreview(objectUrl)
    
        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [uploadedImage])

    const handleFileSelect = (e) =>{
        const file = e.target.files[0];
    if (file) {
        const ext = file.name.split('.').pop();
      if (isValidFileExtension(file.name)) {
        getImageDetails(file, (info) => {
          setImageSize({width: info.width, height: info.height, realWidth: info.realWidth, realHeight: info.realHeight});
          //setUploadError('');
        });
        if (ext === 'png'){
            getPngDPI(file, (err, dpi) => {
                if (err) {
                  setUploadError(err.message);
                  setUploadedImage(null);
                  setImageInfo(null);
                } else {
                  setImageInfo(dpi.x);
                  setUploadError('');
                  setUploadedImage(file);
                }
              });
        } else {
            getImageDPI(file, (dpi) => {  
                setImageInfo((dpi.x.numerator/dpi.x.denominator));
                setUploadError('');
              });
            setUploadedImage(file)
        }
        setUploadError(null);
      } else {
        setUploadedImage(null);
        setUploadError('Invalid file type. Only images and PDFs are allowed.');
      }
    }
        
    }

    // ***** Upload to Firebase storage *****
  const handleUploadToStorage = () => {
    setIsUploading(true);
    setUploadComplete(false)
    const baseURL = "https://uploadfile-pcgrjii2ia-ew.a.run.app";
    const fd = new FormData();
    fd.append('image', uploadedImage)
    axios
      .post(baseURL, fd)
      .then((response) => {
        initializeApp(firebaseConfig)
        const firebaseApp = getApp();
        const storage = getStorage(firebaseApp, firebaseConfig.storageBucket);
        getDownloadURL(ref(storage, uploadedImage.name))
        .then((url) => {
            const imageArray = imageAlbum;
            const imageId = imageArray.length + 1;
            imageArray.unshift({id: imageId, src: url, uri: 'uri'})
            setImageAlbum(imageArray);
            handleUpload(imageId);
        })
      }).catch(error => {
        console.log('ERROR', error)
      });
  }

  const handleUpload = (imageId) => {
    setIsUploading(true);
    const baseURL = "https://api.core3d.io/v1/uploads";
    const fd = new FormData();
    fd.append('file', uploadedImage)
    axios
      .post(baseURL, fd, { headers: { Authorization: `Bearer c3dak_NQCudjy8_8uZnX4oa6H9vEMWnirg29A2s` } })
      .then((response) => {
        setIsUploading(false);
        const imageURI = response.data.uri;
        const index = imageAlbum.findIndex(item => item.id === imageId);
        const current = imageAlbum;
        current[index].uri = imageURI;
        setImageAlbum(current)
        setUploadedImageURI(imageURI)
        setUploadComplete(true)
        setSelectedImage(imageId)
        setPreview(null)
      }).catch(error => {
        console.log('ERROR', error)
        setUploadComplete(false);
      });
  }

  const handleUploadClose = () => {
    setOpenUpload(false);
    setUploadedImage(null);
  }

  useEffect(() => {
    if (!uploadedImage) {
        setPreview(undefined)
        setImageInfo(null)
        setImageSize({width: null, height: null })
        return
    }

    const objectUrl = URL.createObjectURL(uploadedImage)
    setPreview(objectUrl)

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
}, [uploadedImage])

    return (
    <div id="container">
        {/* <Header /> */}
        <Header />
        <div className='merchOuter'>
          <div className='uploadTitle'>Select your merchandise.</div>
          <div className='uploadSubText'>Select the first garment you would like to create</div>
          <div className='merchPanel'>
            <MerchPanel/>
          </div>
        </div>
        <div className='buttonWrapper'>
          <div className='drawerButtons'>
                <button className='continueButton' onClick={() => navigate(-1)}>Back</button>
                {(!selectedMerch) &&
                    <span className='continueButton continueDisabled'>continue</span>
                }
                {(selectedMerch) &&
                    <Link className='continueButton' isdisabled={false} style={{'background': '#ff0098'}} to={`/create`}>create</Link>
                }
          </div>
        </div>
      </div>
    )
}

export default Merch;