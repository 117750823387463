import { useEffect, useState } from 'react';
import './PriceBreakdown.css'

const PriceBreakdown = ( props ) => {
    let { breakdownDetails, totalPrice } = props
    const [showPrices, setShowPrices] = useState(false)

    breakdownDetails = [
        {id: 1, name: 'Premium T-Shirt', showRemove: false},
        {id: 2, name: 'Front print', showRemove: false},
        {id: 3, name: 'Back print', showRemove: true},
    ]

    totalPrice = '£39.99'

    const renderBreakDown = () => {
        const breakdownRender = breakdownDetails.map((item) => {
            return (
                <div className='PBRow'>
                    <div className='PBSubText'>{item.name}</div>
                    <div className='PBSubPrice'>{(item.showRemove) && <button>-</button>}</div>
                </div>
            )
        })
        return breakdownRender;
    }

    const handleClick = () => {
        setShowPrices(!showPrices);
    }

    return (
        <div className='PBOuter'>
            <div className='PBButtonPanel'><button onClick={handleClick}>Click to {(showPrices)?'Hide':'Expand'} Breakdown</button></div>
            <div className={`PBPanel ${(showPrices)? 'showPrices':''}`}>
                {renderBreakDown()}
            </div>
            <div className='PBRow'>
                <div className='PBText'>Total Price</div>
                <div className='PBPrice'>{totalPrice}</div>
            </div>
        </div>
    )
}

export default PriceBreakdown;