import { useState } from 'react';
import DragDropFile from '../DragDrop/DragDrop';

import './AddImage.scss'

const AddImage = ( props ) => {
    const { returnClick, returnDragDrop } = props;
    const [showUpload, setShowUpload] = useState(false)

    const handleBrowseReturn = () => {
        returnClick('browse')
    }
    
    const handleURLReturn= () => {
        returnClick('url')
    }
    
    const handleDragDrop= (e) => {
        returnDragDrop(e);
    }

    const handleShowUpload = () => {
        setShowUpload(!showUpload)
    }

    return (
        <div className='AddImageOuter'>
            <div className='uploadClick' onClick={() => handleShowUpload()}>{(!showUpload)?'Not happy with your image, click here to upload another':'Clicke here to hide the upload'}</div>
            <div className={`showBox ${(showUpload)? 'showUpload':''}`}>
                <DragDropFile returnBrowse={handleBrowseReturn} returnURL={handleURLReturn} returnDragDrop={handleDragDrop} />
            </div>
        </div>
    )
}

export default AddImage;