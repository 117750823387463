// PayPalButton.js
import React, { useState } from 'react';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';

const PayPalButton = ({ amount, orderDetails, currency, onSuccess }) => {
  console.log('paypal', amount)
  return (
    <PayPalScriptProvider options={{ "client-id": "AR5JkOOY2yTcFv3ORwDW4Q_HaT-p0viB_VEkOpsxbdApIluR0c47ZKyUapXZLouvbv44d_5p6QXgdE9e", "currency": currency }}>
      <PayPalButtons
        createOrder={(data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: amount, // Using the passed amount prop
                  currency_code: currency, // Using the selected currency
                },
                description: orderDetails, // Using the passed order details prop
              },
            ],
          });
        }}
        onApprove={(data, actions) => {
          return actions.order.capture().then((details) => {
            // Extracting payer's name and address
            //const addressCheck = details.payment_source.paypal.address;
            const payerNameFirst = details.payer.name.given_name;
            const payerNameLast = details.payer.name.surname;
            const payerName = `${payerNameFirst} ${payerNameLast}`
            const shippingAddress = details.purchase_units[0].shipping.address;

            // Logging or using the payer details
            //console.log('>>>>>', details)
            console.log("Payer Name:", payerNameFirst, payerNameLast);
            console.log("Shipping Address:", shippingAddress);

            // Pass details back to parent component via onSuccess callback
            if (onSuccess) {
              onSuccess({
                payerName,
                shippingAddress,
                details,
              });
            }
          });
        }}
        onError={(err) => {
          console.error("PayPal Checkout onError", err);
        }}
      />
    </PayPalScriptProvider>
  );
};

export default PayPalButton;
