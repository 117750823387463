import Checkout from "../components/organism/Checkout/Checkout";
import YaayLogoSmall from '../components/atom/LogoSmall/LogoSmall';
import FooterNew from '../components/molecule/FooterNew/FooterNew';
import { useState, useEffect, useContext } from "react";
import { useShoppingBasket, operatingContext, selectContext } from '../context/Context';
import CheckoutItem from "../components/molecule/CheckoutItem/CheckoutItem";
import Header from "../components/molecule/Header/Header";
import { useNavigate } from "react-router-dom";

import './Checkout.css'

const CheckoutPage = () => {
    const { state, dispatch } = useShoppingBasket();
    const { currency } = useContext(operatingContext)
    const [ checkoutCost, setCheckoutCost] = useState({productCost: 0, delivery: 0, totalCost: 0})
    const navigate = useNavigate();

    const renderCheckoutItems = () => {
        const returnItems = state.items.map((item, index) => (
            <CheckoutItem item={item} key={index} />
        ))
        return returnItems;
    }

    const calculateCheckoutCost = () => {
        let prodTot = 0;
        let delivery = 0;
        let totalTot = 0;
        state.items.map((item) => {
            prodTot = prodTot + ((item.itemCost*item.itemCount))
        })
        totalTot = (prodTot + delivery);
        setCheckoutCost({productCost: prodTot.toFixed(2), delivery: delivery.toFixed(2), totalCost: totalTot.toFixed(2)})
    }

    const handleBack = () => {
        navigate(-1);
    }

    useEffect(() => {
        calculateCheckoutCost()
    }, [state])
    
    return (
        <div id="container">
            <Header />
            <main>
                <section>
                <div className="cart-page">
        <div className="cart-details">
            <div className="drawerTitle">Checkout</div>
            <div className="returnWrapper">
                <button className="continueButton" onClick={handleBack}>back</button>
            </div>
            <table>
                <thead>
                    <tr>
                        <th>Image</th>
                        <th>Product</th>
                        <th>Price</th>
                        <th>Quantity</th>
                        <th>Sub Total</th>
                        <th>Remove Product</th>
                    </tr>
                </thead>
                <tbody>
                    {renderCheckoutItems()}
                </tbody>
            </table>
        </div>
        <div className="cart-summary">
            <h2>Have coupon?</h2>
            <form action="" method="post" className="coupon-form">
                <input type="text" name="coupon" placeholder="Coupon code"/>
                <button disabled type="submit">Apply</button>
            </form>
            <p>Total Products: {currency.symbol}{checkoutCost.productCost}</p>
            <p>Delivery charges: {currency.symbol}{checkoutCost.delivery}</p>
            <h3>Grand Total: {currency.symbol}{checkoutCost.totalCost}</h3>
            {/* <button className="checkout-button">Checkout</button> */}
            <Checkout totalPrice={checkoutCost.totalCost} />
        </div>
    </div>
                   
                </section>
            </main>
        </div>
    )
}

export default CheckoutPage;