import { useState, useRef, useContext, useEffect } from "react";
import create from '../../../assets/images/create.png'
import { operatingContext, selectContext } from '../../../context/Context';
import { isValidFileExtension, getImageDPI, getPngDPI, getImageDetails, getImageDetailsFromURL, imageUrlToFile } from '../../../helpers/ImageHelpers';
import { getStorage, ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { getApp, initializeApp } from "firebase/app";
import axios from "axios";
import ConfirmBox from "../../atom/ConfirmBox/ConfirmBox";
import {
  Button
} from '@chakra-ui/react'

import './DragDrop.scss'

const DragDropFile = (props) => {
    const { returnBrowse, returnURL, returnDragDrop } = props;
    const [dragActive, setDragActive] = useState(false);
    const inputRef = useRef(null);
    const [preview, setPreview] = useState()
    const {uploadedImage, setUploadedImage} = useContext(selectContext)
    const {uploadedImageURI, setUploadedImageURI} = useContext(selectContext);
    const {selectedImage, setSelectedImage } = useContext(selectContext);
    const {confirmOpen, setConfirmOpen} = useContext(selectContext);
    const [isUploading, setIsUploading] = useState(false);
    const [uploadComplete, setUploadComplete] = useState(false);
    const [uploadError, setUploadError] = useState(null);
    const {imageAlbum, setImageAlbum } = useContext(operatingContext);
    const [imageInfo, setImageInfo] = useState(null)
    const [imageSize, setImageSize] = useState({width: null, height: null });
    const {openUpload, setOpenUpload} = useContext(operatingContext);
    const {openURLUpload, setOpenURLUpload} = useContext(operatingContext);
    const {openDDUpload, setOpenDDUpload} = useContext(operatingContext);
    const [imageUrl, setImageUrl] = useState('');
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadedImageUrl, setUploadedImageUrl] = useState('');
    const [error, setError] = useState('');
    const [imageAssess, setImageAssess] = useState(false);

    const [ddFiles, setDDFiles] = useState(null);
  

    const firebaseConfig = {
      apiKey: process.env.REACT_APP_FB_APIKEY,
      authDomain: process.env.REACT_APP_FB_AUTHDOMAIN,
      projectId: process.env.REACT_APP_FB_PROJECTID,
      storageBucket: process.env.REACT_APP_FB_STORAGEBUCKET,
      messagingSenderId: process.env.REACT_APP_FB_MESSAGINGSENDERID,
      appId: process.env.REACT_APP_FB_APPID,
      measurementId: process.env.REACT_APP_FB_MEASUREMENTID
    };

    // const handleConfirm = result => {
    //   if (result) {

    //     if(result) {
    //       const findImageIndex = (element) => element.id === confirmOpen.imgId;
    //       imageAlbum.splice(imageAlbum.findIndex(findImageIndex), 1)
    //       setImageAlbum(imageAlbum)
    //     }
    //   }
      
    //   setConfirmOpen({state: false, imgId: null});
    // }

    useEffect(() => {
      if (!uploadedImage) {
          setPreview(undefined)
          setImageInfo(null)
          setImageSize({width: null, height: null })
          return
      }
  
      const objectUrl = URL.createObjectURL(uploadedImage)
      setPreview(objectUrl)
  
      // free memory when ever this component is unmounted
      return () => URL.revokeObjectURL(objectUrl)
  }, [uploadedImage])

  // const handleFileSelect = (e) =>{
  //   setUploadComplete(false);
  //     const file = e.target.files[0];
  // if (file) {
  //   console.log('>>>', file)
  //     const ext = file.name.split('.').pop();
  //   if (isValidFileExtension(ext)) {
  //     getImageDetails(file, (info) => {
  //       setImageSize({width: info.width, height: info.height, realWidth: info.realWidth, realHeight: info.realHeight});
  //       //setUploadError('');
  //     });
  //     if (ext === 'png'){
  //         getPngDPI(file, (err, dpi) => {
  //             if (err) {
  //               setUploadError(err.message);
  //               setUploadedImage(null);
  //               setImageInfo(null);
  //             } else {
  //               setImageInfo(dpi.x);
  //               setUploadError('');
  //               setUploadedImage(file);
  //             }
  //           });
  //     } else {
  //         getImageDPI(file, (dpi) => {  
  //             setImageInfo((dpi.x.numerator/dpi.x.denominator));
  //             setUploadError('');
  //           });
  //         setUploadedImage(file)
  //     }
  //     if(imageInfo>140){
  //       setImageAssess(true)
  //     } else {
  //       setImageAssess(false)
  //     }
  //     setUploadError(null);
  //   } else {
  //     setUploadedImage(null);
  //     setUploadError('Invalid file type. Only images and PDFs are allowed.');
  //   }
  // }
      
  // }

  // // ***** Upload to Firebase storage *****
  // const handleUploadToStorage = () => {
  //   setIsUploading(true);
  //   setUploadComplete(false)
  //   const baseURL = "https://uploadfile-pcgrjii2ia-ew.a.run.app";
  //   const fd = new FormData();
  //   fd.append('image', uploadedImage)
  //   axios
  //     .post(baseURL, fd)
  //     .then((response) => {
  //       initializeApp(firebaseConfig)
  //       const firebaseApp = getApp();
  //       const storage = getStorage(firebaseApp, firebaseConfig.storageBucket);
  //       getDownloadURL(ref(storage, uploadedImage.name))
  //       .then((url) => {
  //           const imageArray = imageAlbum;
  //           const imageId = imageArray.length + 1;
  //           imageArray.unshift({id: imageId, src: url, uri: 'uri', imgWidth: imageSize.width, imgHeight: imageSize.height})
  //           setImageAlbum(imageArray);
  //           handleUpload(imageId);
  //       })
  //     }).catch(error => {
  //       console.log('ERROR', error)
  //     });
  // }

  const handleDDUploadToStorage = async(uploadFile) => {
    setIsUploading(true);
    setUploadComplete(false)
    const baseURL = "https://uploadfile-pcgrjii2ia-ew.a.run.app";
    const fd = new FormData();
    fd.append('image', uploadFile)
    axios
      .post(baseURL, fd)
      .then((response) => {
        initializeApp(firebaseConfig)
        const firebaseApp = getApp();
        const storage = getStorage(firebaseApp, firebaseConfig.storageBucket);
        getDownloadURL(ref(storage, uploadFile.name))
        .then((url) => {
            const imageArray = imageAlbum;
            const imageId = imageArray.length + 1;
            imageArray.unshift({id: imageId, src: url, uri: 'uri', imgWidth: imageSize.width, imgHeight: imageSize.height})
            setImageAlbum(imageArray);
            handleUpload(imageId);
        })
      }).catch(error => {
        console.log('ERROR', error)
      });
  }

  // const handleURLUpload = async () => {
  //   const ext = imageUrl.split('.').pop();
  //   const imgFile = await imageUrlToFile(imageUrl);
  //   if (isValidFileExtension(ext)) {
  //     try {
  //       const response = await fetch(imageUrl);
        
  //       if (!response.ok) {
  //         throw new Error('Failed to fetch image.');
  //       } else {
  //         const img = new Image();
  //         img.src = imageUrl;

  //         img.onload = () => {
  //           setUploadedImage(imgFile);
  //           getImageDetailsFromURL(imageUrl, (err, info) => {
  //             if (err) {
  //               setError(err.message);
  //               // setImageDetails(null);
  //             } else {
  //               setError('');
  //               //setImageDetails(details);
  //               setImageSize({width: info.width, height: info.height, realWidth: info.realWidth, realHeight: info.realHeight});
  //               setImageInfo((info.dpi.x));
  //             }
  //           });
  //         }
        
  //         // img.onload = () => {
  //         //   
  //         //   // getImageDetails(file, (info) => {
  //         //   //   setImageSize({width: info.width, height: info.height, realWidth: info.realWidth, realHeight: info.realHeight});
  //         //   //   //setUploadError('');
  //         //   // });
  //         // }
  //       }

  //       initializeApp(firebaseConfig)
  //       const firebaseApp = getApp();
  //       const storage = getStorage(firebaseApp, firebaseConfig.storageBucket);

  //       const blob = await response.blob();
  //       const fileName = imageUrl.split('/').pop();
  //       //const storageRef = ref(storage, `images/${fileName}`);
  //       const storageRef = ref(storage, fileName);
  //       const uploadTask = uploadBytesResumable(storageRef, blob);

  //       uploadTask.on(
  //         'state_changed',
  //         (snapshot) => {
  //           const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
  //           console.log('progress', progress)
  //           setUploadProgress(progress);
  //         },
  //         (error) => {
  //           console.log('ERROR', error.message)
  //         },
  //         () => {
  //           getDownloadURL(uploadTask.snapshot.ref).then((url) => {
  //             const imageArray = imageAlbum;
  //             const imageId = imageArray.length + 1;
  //             imageArray.unshift({id: imageId, src: url, uri: 'uri', imgWidth: imageSize.width, imgHeight: imageSize.height})
  //             setImageAlbum(imageArray);
  //             setImageInfo(null)
  //             setImageSize({width: null, height: null })
  //             //handleUpload(imageId);
  //           });
  //         }
  //       );
  //     } catch (err) {
  //       console.log('ERROR', err)
  //     }
  //   } else {
  //     setUploadedImage(null);
  //     setUploadError('Invalid file type. Only images and PDFs are allowed.');
  //   }
  // };

  const handleUpload = (imageId) => {
    setIsUploading(true);
    const baseURL = "https://api.core3d.io/v1/uploads";
    const fd = new FormData();
    fd.append('file', uploadedImage)
    axios
      .post(baseURL, fd, { headers: { Authorization: `Bearer c3dak_NQCudjy8_8uZnX4oa6H9vEMWnirg29A2s` } })
      .then((response) => {
        setIsUploading(false);
        const imageURI = response.data.uri;
        const index = imageAlbum.findIndex(item => item.id === imageId);
        const current = imageAlbum;
        current[index].uri = imageURI;
        setImageAlbum(current)
        setUploadedImageURI(imageURI)
        setUploadComplete(true)
        setSelectedImage(imageId)
        setPreview(null)
        setImageInfo(null)
        setImageSize({width: null, height: null });
      }).catch(error => {
        console.log('ERROR', error)
        setUploadComplete(false);
      });
  }

  

  const handleDragDrop = async (files) => {
    const file = files[0];
    if (file) {
      setOpenDDUpload(true)
      const ext = file.name.split('.').pop();
      if (isValidFileExtension(ext)) {
        getImageDetails(file, (info) => {
          setImageSize({width: info.width, height: info.height, realWidth: info.realWidth, realHeight: info.realHeight});
          //setUploadError('');
        });
        if (ext === 'png'){
            getPngDPI(file, (err, dpi) => {
                if (err) {
                  setUploadError(err.message);
                  setUploadedImage(null);
                  setImageInfo(null);
                } else {
                  setImageInfo(dpi.x);
                  setUploadError('');
                  setUploadedImage(file);
                }
              });
        } else {
            getImageDPI(file, (dpi) => {  
                setImageInfo((dpi.x.numerator/dpi.x.denominator));
                setUploadError('');
              });
            setUploadedImage(file)
        }
        const objectUrl = URL.createObjectURL(file)
        if(imageInfo>140){
          setImageAssess(true)
        } else {
          setImageAssess(false)
        }
        setUploadError(null);
        setDDFiles([{file: file, imageURL: objectUrl, imageSize: 'imageDetails', imageInfo: imageInfo, imageAssess: imageAssess}]);

      } else {
        setUploadedImage(null);
        setUploadError('Invalid file type. Only images and PDFs are allowed.');
      }
    }
    
    // ********************************
    // Original multi file handling
    // const dragFiles = [...files];
    // setOpenDDUpload(true)
    // const ddFileArray = []

    // dragFiles.forEach(file => {
    //   let imageDetails, imageInfo, imageAssess
    //   if (file) {
        
    //     const ext = file.name.split('.').pop();
    //     if (isValidFileExtension(ext)) {
          
    //       imageDetails = getImageDetails(file, (info) => {
    //         //setImageSize({width: info.width, height: info.height, realWidth: info.realWidth, realHeight: info.realHeight});
    //         imageDetails = {width: info.width, height: info.height, realWidth: info.realWidth, realHeight: info.realHeight}
    //       })
    //       if (ext === 'png'){
    //           getPngDPI(file, (err, dpi) => {
    //               if (err) {
    //                 setUploadError(err.message);
    //                 // setUploadedImage(null);
    //                 // setImageInfo(null);
    //               } else {
    //                 setUploadError('');
    //                 //setImageInfo(dpi.x);
    //                 imageInfo = dpi.x
    //                 //setUploadedImage(file);
    //               }
    //             });
    //       } else {
    //           getImageDPI(file, (dpi) => {  
    //               //setImageInfo((dpi.x.numerator/dpi.x.denominator));
    //               imageInfo = (dpi.x.numerator/dpi.x.denominator)
    //               setUploadError('');
    //             });
    //           //setUploadedImage(file)
    //       }
    //       const objectUrl = URL.createObjectURL(file)
    //       if(imageInfo>140){
    //         imageAssess = true
    //       } else {
    //         imageAssess = false
    //       }
    //       setUploadError(null);
    //       ddFileArray.push({file: file, imageURL: objectUrl, imageSize: imageDetails, imageInfo: imageInfo, imageAssess: imageAssess})
    //     } else {
    //       setUploadedImage(null);
    //       setUploadError('Invalid file type. Only images and PDFs are allowed.');
    //     }
    //   }
    // })
  }

  const handleBrowseReturn = () => {
    setOpenUpload(true)
  }

  const handleURLReturn = () => {
    setOpenURLUpload(true)
  }

  async function processItems(items){
    for (const file of items) {
      await handleDDUploadToStorage(file.file);
    }
  }

  const handleDDUpload = () => {
    if(!ddFiles){
      setError('No files to upload')
      return;
    }
    processItems(ddFiles)
  }

  useEffect(() => {
    if (!uploadedImage) {
        setPreview(undefined)
        setImageInfo(null)
        setImageSize({width: null, height: null })
        return
    }

    const objectUrl = URL.createObjectURL(uploadedImage)
    setPreview(objectUrl)

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [uploadedImage])


    function handleFile(files) {
        returnDragDrop(files)
      }
    
    // handle drag events
    const handleDrag = function(e) {
      e.preventDefault();
      e.stopPropagation();
      if (e.type === "dragenter" || e.type === "dragover") {
        setDragActive(true);
      } else if (e.type === "dragleave") {
        setDragActive(false);
      }
    };
    
    // triggers when file is dropped
    const handleDrop = function(e) {
      e.preventDefault();
      e.stopPropagation();
      setDragActive(false);
      if (e.dataTransfer.files && e.dataTransfer.files[0]) {
        handleFile(e.dataTransfer.files);
      }
    };
    
    const handleBrowse = () => {
        setOpenUpload(true)
    }

    const handleURL = () => {
        setOpenURLUpload(true)
    }
    
    return (
        <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
          {/* <input ref={inputRef} type="file" id="input-file-upload" multiple={true} onChange={handleChange} /> */}
          <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? "drag-active" : "" }>
            <div className='uploadLogo'>
              <div className='createLogo'>
                <img src={create} className='' onClick={() => handleBrowse()}/>
              </div>
            </div>
            <div className="uploadTextOuter">
              <div className='uploadTitle'>Upload your images to create your unique merchandise.</div>
              <div className='uploadSubText'>You can drag and drop your files here or <span className='textLink' onClick={handleBrowse}>browse</span> your computer or add <span className='textLink' onClick={handleURL}> image URLs</span></div>
            </div> 
          </label>
          { dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div> }
        </form>
    );
  };

  export default DragDropFile;