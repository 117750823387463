import { useContext, useEffect, useState } from "react";
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import PayPalButton from "../../molecule/PayPal/PayPalButtons";
import { useNavigate } from "react-router-dom";
import { operatingContext } from "../../../context/Context";


const Checkout = (props) => {
    const { totalPrice } = props;
    const [orderAmount, setOrderAmount] = useState(0); // Replace with dynamic data if needed
    const [orderDescription, setOrderDescription] = useState("Purchase of XYZ items");
    //const [currency, setCurrency] = useState("USD");
    const { currency} = useContext(operatingContext)


    const navigate = useNavigate();
    
    // Callback to handle order success
  const handleSuccess = (data) => {
    
    console.log("Transaction Details:", data.details);
    navigate('/orderConfirm', { state: { orderDetails: JSON.stringify(data.details), billAddress: JSON.stringify(data.details.purchase_units[0].shipping.address), shipAddress: JSON.stringify(data.details.purchase_units[0].shipping.address) }});
    // Use shippingAddress and payerName as needed
  };

  useEffect(() => {
    setOrderAmount(totalPrice)
  })
    
    return (
        <div className="checkoutOuter">
            {/* <div>
                <label htmlFor="currency">Select Currency: </label>
                <select
                id="currency"
                value={currency}
                onChange={(e) => setCurrency(e.target.value)}
                >
                <option value="USD">USD</option>
                <option value="HKD">HKD</option>
                <option value="EUR">EUR</option>
                <option value="GBP">GBP</option>
                </select>
            </div> */}

            <PayPalButton
                amount={orderAmount}
                orderDetails={orderDescription}
                currency={currency}
                onSuccess={handleSuccess}
            />
        </div>
    )
}

export default Checkout;