import './OrientationBox.css';

const OrientationBox = (props) => {

    const {oriValue, isSelected, returnClick} = props;
    const handleClick = ()   => {
            returnClick(oriValue.text);
    }

    return (
        <div className={`placementBox ${(isSelected)?'placeSelected':''}`} onClick={handleClick}>
            <img className='placementBase' src={oriValue.image} />
            <div className={`oriName`}>{oriValue.text}</div>

        </div>
    )
}

export default OrientationBox;